.spg-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background-color: $background;
  box-sizing: border-box;
  width: 100%;
  height: calcSize(6);
  border: 1px solid $border-inside;
  padding: calcSize(1.5) calcSize(2);
  outline: none;
  font-size: calcSize(2);
  font-family: $font-family;
  color: $foreground;
}

.spg-input::placeholder {
  color: $foreground-light;
}

.spg-input:focus {
  border-color: $primary;
  box-shadow: inset 0 0 0 1px $primary;
}

.spg-input:disabled,
.spg-input:disabled::placeholder {
  color: $foreground-disabled;
}

.spg-input[type="color"] {
  padding-left: 0;
  padding-right: 0;
  min-width: calcSize(8);
}
