.sd-selectbase {
  border: none;
  margin: 0;
  padding: 0;
}

.sd-selectbase--row {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: calcSize(4);
}

.sd-selectbase--multi-column {
  display: flex;
  flex: 1 1 0px;
}

.sd-selectbase__label {
  display: flex;
  position: relative;
  gap: calcSize(1);
}
.sd-selectbase__column {
  vertical-align: top;
}

.sd-selectbase__column {
  display: block;
  box-sizing: border-box;
  word-break: break-word;
  flex: 1 1 0px;
}
.sd-selectbase__column:not(:last-child) {
  padding-right: calcSize(2);
}

// Copied from stylesmanager
.sd-selectbase__column.sv-q-column-1 {
  width: 100%;
}
.sd-selectbase__other {
  margin-top: calcSize(2);
}
.sd-selectbase__item {
  .sd-selectbase__other {
    margin-top: calcSize(3.5);
  }
}
.sd-selectbase__item:last-child {
  .sd-selectbase__other {
    margin-bottom: calcSize(-2);
  }
}
