@import "../../common-styles/sv-list.scss";

.sd-list {
  padding-right: calcSize(0.5);
  padding-left: calcSize(0.5);
}

.sd-list__item.sd-list__item {
  padding-top: calcSize(1.5);
  padding-bottom: calcSize(1.5);
  border-radius: 3px;
}

.sd-list__item--selected.sd-list__item--selected {
  background-color: $primary-light;
  color: $foreground;
}

.sd-list__item--selected {

  &:hover,
  &:focus {
    background-color: $primary;
    color: $primary-foreground;
  }
}