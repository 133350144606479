@import "../../variables.scss";

.svc-tabbed-menu-item {
  padding: calcSize(2) calcSize(3);
  height: calcSize(8);
  line-height: calcSize(4);
  box-sizing: border-box;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: $background-dim;
    box-shadow: inset 0px -1px 0px $border;
    outline: none;
  }
}

.svc-tabbed-menu-item--selected {
  background: $background;
  box-shadow: inset 0px -2px 0px $primary;
  &:hover,
  &:focus {
    background: $background;
    box-shadow: inset 0px -2px 0px $primary;
  }
}

.svc-tabbed-menu-item--disabled {
  cursor: default;
  color: $foreground-disabled;
  &:hover {
    background-color: $background;
  }
}

.svc-tabbed-menu-item--hidden {
  visibility: hidden;
}
.svc-tabbed-menu-item {
  .svc-tabbed-menu-item__text {
    white-space: nowrap;
    line-height: calcSize(4);
  }
}

.svc-tabbed-menu-item-container .sv-dots__item {
  width: auto;
}

#action-undo,
#action-redo {
  .sv-action-bar-item .sv-action-bar-item__icon use {
    opacity: 0.25;
  }
}

#action-undo,
#action-redo {
  .sv-action-bar-item--active .sv-action-bar-item__icon use {
    fill: $foreground-light;
    opacity: 1;
  }
}
