.svc-btn {
  &:hover {
    box-shadow: 0 0 0 2px $primary;
  }
  display: flex;
  align-items: center;
  height: calcSize(7);
  line-height: calcSize(7);
  vertical-align: baseline;
  text-align: center;
  background-color: $background;
  box-shadow: 0px 1px 2px $shadow-inner;
  border-radius: calcSize(0.5);
  cursor: pointer;
  user-select: none;
  outline-color: $primary;

  .svc-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    color: $primary;
  }
}
