@import "../../variables.scss";

svc-tab-json-editor-textarea {
  width: 100%;
  height: 100%;
  background: $background-dim;
}

.svc-json-editor-tab__content {
  position: relative;
  height: 100%;
}

.svc-json-editor-tab__content-area {
  width: 100%;
  height: 100%;
}

.svc-json-editor-tab__errros_button {
  position: absolute;
  top: 0%;
  right: calc(0% + calc(2 * #{$base-unit}));
  min-height: calc(2.5 * #{$base-unit});
  max-height: calc(2.5 * #{$base-unit});
  border: none;
  background-color: $primary;
  color: $primary-foreground;
  font-weight: bold;
}

.svc-json-editor-tab__content-errors {
  position: absolute;
  right: 0%;
  top: calc(0% + calc(3 * #{$base-unit}));
  max-width: calc(100% - calc(2 * #{$base-unit}));
  padding: $base-unit;

  background-color: $background;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
  border: 1px solid $border;
  border-radius: 1px;
  
  color: $foreground;
  font-family: monospace;

  span {
    white-space: pre-line;

    > b {
      color: $red;
    }
  }  
}

.svc-json-editor-tab__content-errors:empty {
  display: none;
}