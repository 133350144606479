.spg-action-bar {
  display: flex;
  box-sizing: content-box;
  padding-right: calcSize(0.5);
  position: relative;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
  white-space: nowrap;
  .sv-action:not(.sv-action--hidden):not(:last-of-type)>.sv-action__content {
    padding-right: 0;
  }
}
.spg-action-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: calcSize(1);
  box-sizing: border-box;
  border: none;
  border-radius: calcSize(12.5);
  background-color: transparent;
  cursor: pointer;
  &:hover,
  &:focus {
      opacity: 1;
      outline: none;
      background-color: $primary-light;
      use {
          fill: $primary;
      }
  }
}
.spg-action-button__icon {
  display: block;
  use {
    fill: $foreground-light;
  }
}
.spg-action-button--danger {
  &:hover,
  &:focus {
    background-color: $red-light;
    use {
        fill: $red;
    }
  }
}
button.spg-action-button--large {
  padding: calcSize(0.5) calcSize(2);
}
.spg-action-button:disabled {
  opacity: 0.25;
  pointer-events: none;
  cursor: default;
}
.spg-action-button--muted {
  opacity: 0.5;
}
.spg-action-button:active {
  opacity: 0.5;
}