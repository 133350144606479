@import "../../variables.scss";

svc-page-navigator-item,
.svc-page-navigator-item {
  display: block;
  width: calcSize(5.5);
  height: calcSize(4.5);
  min-height: calcSize(4.5);
  cursor: pointer;
  position: relative;
}

.svc-page-navigator-item-content {
  height: 100%;
  width: 100%;
  &:hover,
  &:focus {
    outline: none;
  }
}

.svc-page-navigator-item__dot {
  box-sizing: content-box;
  position: absolute;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  right: calc(50% - 6px / 2);
  top: calc(50% - 6px / 2);
  background: $border;
}

.svc-page-navigator-item--selected {
  .svc-page-navigator-item__dot {
    width: calcSize(1);
    height: calcSize(1);
    right: calc(50% - 1.5 * #{$base-unit} / 2);
    top: calc(50% - 1.5 * #{$base-unit} / 2);
    background: $background;
    border: calcSize(0.25) solid $primary;
  }
}

.svc-page-navigator-item__banner {
  @include textEllipsis;

  right: calcSize(0.5);
  display: flex;
  align-items: center;
  line-height: calcSize(4);
  animation: 0.5s ease-in;
  padding: 0;
  opacity: 0;
  z-index: 20;

  .svc-page-navigator-item__dot {
    position: absolute;
    display: inline-block;
    top: calcSize(1.75);
    right: calcSize(1.75);
  }
}

.svc-creator__toolbox--right {
  .svc-page-navigator-item__banner {
    right: unset;
    left: calc(0.625 * var(--base-unit, 8px));
    .svc-page-navigator-item__dot {
      right: unset;
      left: calcSize(1.75);
    }
  }
}

.svc-page-navigator-item-content:not(.svc-page-navigator-item--disabled) {
  .svc-page-navigator-item__banner {
    .svc-page-navigator-item__dot {
      width: calcSize(1);
      height: calcSize(1);
      border: none;
      background: $primary;
    }
  }
}

.svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover,
.svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus {
  .svc-page-navigator-item__banner {
    padding: 0 calcSize(4.5) 0 calcSize(2.5);
    max-width: calcSize(25);
    opacity: 1;
  }
}

.svc-creator__toolbox--right {
  .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover,
  .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus {
    .svc-page-navigator-item__banner {
      padding: 0 calcSize(2.5) 0 calcSize(4.5);
    }
  }
}

.svc-page-navigator-item--disabled {
  .svc-page-navigator-item__banner {
    color: $foreground-disabled;
  }
}
